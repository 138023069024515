import React, { FC } from "react";
import { muiStylesButtons } from "../muiStylesButtons";
import { primaryColorDisabled } from "../../../../styles/mui/theme";
import { Button, CircularProgress } from "@mui/material";

interface Props {
  text: string;
  loading?: boolean;
  disabled?: boolean;
  event?: () => void;
  isSubmit?: boolean;
  loginBtn?: boolean;
}

const PrimaryButton: FC<Props> = ({
  text,
  disabled,
  event,
  isSubmit,
  loading,
  loginBtn,
}) => {
  const isDisabled = !!disabled;
  const isLoginBtn = !!loginBtn;

  return (
    <Button
      sx={isLoginBtn ? muiStylesButtons.loginButton : muiStylesButtons.primaryButton}
      // Inline styling was added because of a reported issue, OPIT1-117
      style={
        isDisabled
          ? {
            backgroundColor: primaryColorDisabled,
          }
          : {}
      }
      disabled={isDisabled}
      onClick={event}
      type={isSubmit ? "submit" : "button"}
    >
      {loading ? (
        <CircularProgress sx={muiStylesButtons.loader} size={24} />
      ) : (
        text
      )}
    </Button>
  );
};

export default PrimaryButton;